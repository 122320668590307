<template>
  <div class="information" style="margin: -15px -15px ">
    <a-spin :spinning="spinning">
   <div>
     <div class="information-top">
       <div class="information-top-title">
         {{liveTitle}}
       </div>
       <a-menu
           v-model="current"
           style="border-bottom: 10px solid #f5f5f5;padding-top: 10px"
           mode="horizontal">
         <a-menu-item
             data-id="1"
             @click="selectState"
             key="1">原始观看记录</a-menu-item>
         <a-menu-item
             data-id="2"
             @click="selectState"
             key="2">人员观看总时长</a-menu-item>
         <a-menu-item
             data-id="3"
             @click="selectState"
             key="3">姓名医院登录记录</a-menu-item>
         <a-menu-item
             data-id="4"
             @click="selectState"
             key="4">聊天室记录</a-menu-item>
       </a-menu>
     </div>
     <div class="information-table">
       <div class="information-table-btn" style="width: 100%">
         <div
             v-show="liveWatch"
             style="width: 20%;display: flex;height: 35px;line-height: 35px">
           <div style="width: 70px">观看类型: </div>
           <a-select
               v-model="watch"
               placeholder="请选择类型"
               style="width: 150px;"
               @change="selectChange">
             <!--            LIVE：直播 VOD：点播 DBHF：点播回放      -->
             <a-select-option value="LIVE">
               直播
             </a-select-option>
             <a-select-option value="VOD">
               点播
             </a-select-option>
             <a-select-option value="DBHF">
               点播回放
             </a-select-option>
           </a-select>
         </div>
         <div style="width: 20%;display: flex;height: 35px;line-height: 35px;margin-left: 15px">
           <div style="width: 60px">直播间: </div>
           <a-select
               v-model="liveData"
               placeholder="请选择类型"
               style="width: 150px;"
               @change="selectDownload">
             <!--            LIVE：直播 VOD：点播 DBHF：点播回放      -->
             <a-select-option value="">
               全部
             </a-select-option>
             <a-select-option
                 v-for="(live,index) in LiveSelectLive"
                 :value="live.id">
               {{live.title}}
             </a-select-option>
           </a-select>
         </div>
         <div style="width: 60%;margin-left: 50px">
           <a-button
               @click="searchBtn"
               type="primary">查询</a-button>
           <a-button
               @click="restBtn"
               style="margin-left: 10px">重置</a-button>
         </div>
         <div style="width: 20%;text-align: center">
           <a-button
               @click="exportBtn"
               style="margin-left: 10px">
             <a-icon type="file-excel" style="color: #1ea134;font-size: 16px" />
             批量导出
           </a-button>
         </div>
       </div>


       <!--      表格-->
       <!--      原始观看记录-->
       <div v-show="tab==='1'">
         <a-table
             style="margin-top: 20px;width: 98%;"
             :columns="columns_Original_viewing"
             :rowKey='record=>record.id'
             :pagination="false"
             :data-source="data_Original_viewing">
           <span slot="startTime" slot-scope="text, record">
              <div>
                {{(record.startTime).slice(0,10)}}
              </div>
            </span>
           <span slot="endTime" slot-scope="text, record">
              <div>
                {{(record.startTime).slice(11,19)}}~{{(record.endTime).slice(11,19)}}
<!--                {{(record.startTime).slice(11,9)}}~{{(record.endTime).slice(11,9)}}-->
              </div>
            </span>
         </a-table>
         <div style="width: 100%;height: 50px;">
           <div style="float: right;margin-right: 20px;margin-top: 15px">
             <a-pagination
                 :current="page_no"
                 @change="listChange_Original_viewing"
                 :total="tableCount_Original_viewing" />
           </div>
         </div>
       </div>
       <!--      人员观看总时长-->
       <div v-show="tab==='2'">
         <a-table
             style="margin-top: 20px;width: 98%;"
             :columns="columns_People_watch"
             :rowKey='record=>record.id'
             :pagination="false"
             :data-source="data_People_watch">
         </a-table>
         <div style="width: 100%;height: 50px;">
           <div style="float: right;margin-right: 20px;margin-top: 15px">
             <a-pagination
                 :current="page_no"
                 @change="listChange_People_watch"
                 :total="tableCount_People_watch" />
           </div>
         </div>
       </div>
       <!--      姓名医院登记记录-->
       <div v-show="tab==='3'">
         <a-table
             style="margin-top: 20px;width: 98%;"
             :columns="columns_name_hos"
             :rowKey='record=>record.id'
             :pagination="false"
             :data-source="data_name_hos">
           <span slot="hospitalOffices" slot-scope="text, record">
             {{record.hospitalOffices === undefined || record.hospitalOffices ===  null || record.hospitalOffices === '' ? ' ' :record.hospitalOffices}}
           </span>
         </a-table>
         <div style="width: 100%;height: 50px;">
           <div style="float: right;margin-right: 20px;margin-top: 15px">
             <a-pagination
                 :current="page_no"
                 @change="listChange_name_hos"
                 :total="tableCount_name_hos" />
           </div>
         </div>
       </div>
       <!--      聊天记录-->
       <div v-show="tab==='4'">
         <a-table
             style="margin-top: 20px;width: 98%;"
             :columns="columns_chatting_records "
             :rowKey='record=>record.id'
             :pagination="false"
             :data-source="data_chatting_records">
           <span slot="terminal" slot-scope="text, record">
             <a-popconfirm
                 title="是否确认删除?"
                 ok-text="是"
                 cancel-text="否"
                 @confirm="delConfirm(record.id)"
                 @cancel="cancelDel"
             >
                 <a >删除</a>
            </a-popconfirm>
              <a
                  @click="lookBtn(record)"
                  style="margin-left: 10px">查看</a>
            </span>
           <span slot="isShow" slot-scope="text, record">
             <a-switch
                 :defaultChecked="record.vodHide !==1 "
                 checked-children="是"
                 un-checked-children="否"
                 @change="selectSwitch(record.id,record.vodHide === 1? 0 : 1)"
                 default-checked />
            </span>
         </a-table>
         <div style="width: 100%;height: 50px;">
           <div style="float: right;margin-right: 20px;margin-top: 15px">
             <a-pagination
                 :current="page_no"
                 @change="listChange_chatting_records"
                 :total="tableCount_chatting_records" />
           </div>
         </div>
       </div>

<!--------------------------------------------   对话框   ---------------------------->
       <a-drawer
           title="查看记录"
           placement="right"
           :closable="false"
           :visible="visible"
           @close="onClose"
           width="650px"
       >
         <div>
           <div style="display: flex;justify-content: space-between;width: 90%;margin: auto;height: 30px;line-height: 30px">
             <div style="display: flex;">
               <div>姓名：{{lookName}} </div>
               <div style="margin-left: 15px"> 手机号：{{lookTel}}</div>
             </div>
             <div>
               <a-button @click="delModelBtn">
                 <a-icon style="background-color: #fff;color: #f82c2c;font-size: 15px" type="close-circle" />
                 批量删除
               </a-button>
             </div>
           </div>
           <a-table
               style="margin-top: 10px"
               :rowKey='record=>record.id'
               :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
               :columns="columnsLook"
               :data-source="dataLook">
           </a-table>
         </div>
         <div
             :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
         >
           <a-button style="marginRight: 8px" @click="onClose">
             关闭
           </a-button>
         </div>
       </a-drawer>
     </div>
   </div>
    </a-spin>
    <!-- 底部   -->
    <div style="height: 60px"></div>
    <div style="width:100%;height:50px;position: fixed;bottom: 50px;background-color: #f0f2f5;border-bottom: 1px solid #fff">
      <div
          style="width: 80%;display: flex;justify-content: right;margin-top: 10px">
        <a-button @click="returnBtn">返回上一层</a-button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {
  delList,
  Download_ChattingRecords_exal,
  Download_NameHos_exal,
  Download_OriginalViewing_exal,
  Download_PeopleWatch_exal,
  get_chatting_records,
  get_name_hos,
  get_Original_viewing,
  get_People_watch,
  getListContent,
  getSetLiveList,
  putVodHide
} from "../../service/liveMeet_api";
import {getExel} from "../../service/base";

const columns_Original_viewing = [
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
    width: '8%',
    // scopedSlots: { customRender: 'name' },
  },
  {
    title: '医院',
    dataIndex: 'hospitalName',
    key: 'hospitalName',
    width: '15%',
  },
  {
    title: '科室',
    dataIndex: 'hospitalOffices',
    key: 'hospitalOffices',
    ellipsis: true,
    width: '10%',
  },
  {
    title: '职称',
    dataIndex: 'jobTitle',
    key: 'jobTitle',
    ellipsis: true,
    width: '8%',
  },
  {
    title: '身份',
    dataIndex: 'memberType',
    key: 'memberType',
    ellipsis: true,
    width: '8%',
  },
  {
    title: '观看日期',
    dataIndex: 'startTime',
    key: 'startTime',
    ellipsis: true,
    width: '10%',
    scopedSlots: { customRender: 'startTime' },
  },
  {
    title: '观看时间',
    dataIndex: 'endTime',
    key: 'endTime',
    ellipsis: true,
    width: '15%',
    scopedSlots: { customRender: 'endTime' },
  },
  {
    title: 'IP地址',
    dataIndex: 'ip',
    key: 'ip',
    ellipsis: true,
    width: '12%',
  },
  {
    title: '观看类型',
    dataIndex: 'type',
    key: 'type',
    ellipsis: true,
    width: '8%',
  },
  {
    title: '终端',
    dataIndex: 'channel',
    key: 'channel',
    ellipsis: true,
    width: '8%',
  },
];
const columns_People_watch = [
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
    width: '8%',
    // scopedSlots: { customRender: 'name' },
  },
  {
    title: '医院',
    dataIndex: 'hospitalName',
    key: 'hospitalName',
    width: '15%',
  },
  {
    title: '科室',
    dataIndex: 'hospitalOffices',
    key: 'hospitalOffices',
    ellipsis: true,
  },
  {
    title: '职称',
    dataIndex: 'jobTitle',
    key: 'jobTitle',
    ellipsis: true,
  },
  {
    title: '身份',
    dataIndex: 'memberType',
    key: 'memberType',
    ellipsis: true,
  },
  {
    title: '观看时长(分钟)',
    dataIndex: 'duration',
    key: 'duration',
    ellipsis: true,
  },
  {
    title: 'IP地址',
    dataIndex: 'ip',
    key: 'ip',
    ellipsis: true,
  },
  {
    title: '观看类型',
    dataIndex: 'watchType',
    key: 'watchType',
    ellipsis: true,
  },
];
const columns_name_hos = [
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
    width: '10%',
    // scopedSlots: { customRender: 'name' },
  },
  {
    title: '医院',
    dataIndex: 'hospitalName',
    key: 'hospitalName',
    width: '20%',
  },{
    title: '科室',
    dataIndex: 'hospitalOffices',
    key: 'hospitalOffices',
    width: '10%',
    scopedSlots: { customRender: 'hospitalOffices' },
  },
  {
    title: '登陆时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    ellipsis: true,
  },
  {
    title: 'IP地址',
    dataIndex: 'ip',
    key: 'ip',
    ellipsis: true,
  },
  {
    title: '终端',
    dataIndex: 'channel',
    key: 'channel',
    ellipsis: true,
  },
];
const columns_chatting_records = [
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
    // scopedSlots: { customRender: 'name' },
  },
  {
    title: '手机号',
    dataIndex: 'tel',
    key: 'tel',
    // scopedSlots: { customRender: 'tel' },
  },
  {
    title: '发送内容',
    dataIndex: 'message',
    key: 'message',
    // scopedSlots: { customRender: 'message' },
  },
  {
    title: '点播隐藏',
    dataIndex: 'isShow',
    key: 'isShow',
    ellipsis: true,
    scopedSlots: { customRender: 'isShow' },
  },
  {
    title: '操作',
    dataIndex: 'terminal',
    key: 'terminal',
    ellipsis: true,
    scopedSlots: { customRender: 'terminal' },
  },
];
const columnsLook = [
  {
    title: '聊天内容',
    dataIndex: 'message',
    key: 'message',
    ellipsis: true,
  },
];
const dataLook = [

]
export default {
  data() {
    return {
      liveWatch:true,
      data_Original_viewing:[],
      data_People_watch:[],
      data_name_hos:[],
      data_chatting_records:[],

      count_Original_viewing:null,
      count_People_watch:null,
      count_name_hos:null,
      count_chatting_records:null,

      columns_Original_viewing,
      columns_People_watch,
      columns_name_hos,
      columns_chatting_records,
      page_no:1,
      page_size:10,
      tableCount:null,
      tableCount_Original_viewing:null,
      tableCount_People_watch:null,
      tableCount_name_hos:null,
      tableCount_chatting_records:null,
      watch:undefined,
      liveData:undefined,
      current:['1'],
      tab:'1',
      spinning:false,
      isShow:1,
      visible:false,
      columnsLook,
      dataLook,
      selectedRowKeys:[],
      user_id:'',
      lookName:'',
      lookTel:'',
      LiveSelectLive:[],
      liveModelId:'',
      liveTitle:''
    };
  },
  created() {
    this.getLiveOneList()
    //当tabs导航在第一个状态下时加载
    if(this.tab === '1'){
      this.getOriginalViewing()
    }
    this.getLiveSelectLive()
  },
  methods: {
    //获取单个会议
    async getLiveOneList() {
      const response = await getListContent(this.$route.query.live_metting_id)
      if (response.code === 0) {
        this.liveTitle = response.data.title

      } else {
        this.$message.warning("失败请联系管理员" + response.message)
      }
    },
    //获取直播间列表
    async getLiveSelectLive() {
       let data = {
         liveMettingId : this.$route.query.live_metting_id
       }
      const response = await getSetLiveList(data)
      if(response.code === 0){
        this.LiveSelectLive = response.data.rows
      }
    },
    //获取表格数据
    //原始观看记录
    async getOriginalViewing(page_no,page_size) {
      this.spinning = true
      const liveMettingId = this.$route.query.live_metting_id
      const type = this.watch
      const streamId = this.liveData
      // console.log(liveMettingId,type,streamId)
      let data = {
        liveMettingId:liveMettingId,
        type:type,
        streamId:streamId,
        page_no:page_no,
        page_size:page_size
      }
      const response = await get_Original_viewing(data)
      if(response.code === 0){
        this.data_Original_viewing = response.data.rows
        this.tableCount_Original_viewing = response.data.count
      }else {
        this.$message.warning("失败，请联系管理员",response.message)
      }
      this.spinning = false
    },
    //人员观看总时长
    async getPeopleWatch(page_no,page_size) {
      this.spinning = true
      const liveMettingId = this.$route.query.live_metting_id
      const type = this.watch
      const streamId = this.liveData
      let data = {
        liveMettingId:liveMettingId,
        type:type,
        streamId:streamId,
        page_no:page_no,
        page_size:page_size
      }
      const response = await get_People_watch(data)
      if(response.code === 0){
        this.data_People_watch = response.data.rows
        this.tableCount_People_watch = response.data.count
      }else {
        this.$message.warning("失败，请联系管理员",response.message)
      }
      this.spinning = false
    },
    //姓名医院登记记录
    async getNameHos(page_no,page_size) {
      this.spinning = true
      const liveMettingId = this.$route.query.live_metting_id
      const type = this.watch
      const streamId = this.liveData
      let data = {
        liveMettingId:liveMettingId,
        type:type,
        streamId:streamId,
        page_no:page_no,
        page_size:page_size
      }
      const response = await get_name_hos(data)
      if(response.code === 0){
        this.data_name_hos = response.data.rows
        // this.count_name_hos = response.data.count
        this.tableCount_name_hos = response.data.count
      }else {
        this.$message.warning("失败，请联系管理员",response.message)
      }
      this.spinning = false
    },
    //聊天记录
    async getChattingRecords(page_no,page_size) {
      this.spinning = true
      const liveMettingId = this.$route.query.live_metting_id
      const type = this.watch
      const streamId = this.liveData
      let data = {
        liveMettingId:liveMettingId,
        type:type,
        roomId:streamId,
        page_no:page_no,
        page_size:page_size
      }
      const response = await get_chatting_records(data)
      if(response.code === 0){
        this.data_chatting_records = response.data.rows
        this.tableCount_chatting_records = response.data.count
      }else {
        this.$message.warning("失败，请联系管理员",response.message)
      }
      this.spinning = false
    },
    //选择类型
    selectChange(value){
      this.watch = value
      let tab = this.tab
      if(tab === '1'){
        //原始观看记录
        this.getOriginalViewing(this.page_no,this.page_size)
      }
      if(tab === '2'){
        //人员观看总时长
        this.getPeopleWatch()
      }
      // if(tab === '3'){
      //   //姓名医院登录记录
      //   this.getNameHos()
      // }
      // if(tab === '4'){
      //   //聊天室记录
      //   this.getChattingRecords()
      // }
    },
    //选择类型
    selectDownload(value){
      this.liveData = value
      let tab = this.tab
      if(tab === '1'){
        //原始观看记录
        this.getOriginalViewing(this.page_no,this.page_size)
      }
      if(tab === '2'){
        //人员观看总时长
        this.getPeopleWatch()
      }
      if(tab === '3'){
        //姓名医院登录记录
        this.getNameHos()
      }
      if(tab === '4'){
        //聊天室记录
        this.getChattingRecords()
      }
    },
    //tabs选择
    selectState(e){
      this.page_no = 1
      this.watch = undefined
      this.liveData = undefined
      this.tab = e.key
      if(e.key === '1'){
        this.liveWatch = true
      //原始观看记录
        this.getOriginalViewing()
        this.restBtn()
      }
      if(e.key === '2'){
        this.liveWatch = true
        //人员观看总时长
        this.getPeopleWatch()
        this.restBtn()
      }
      if(e.key === '3'){
        this.liveWatch = false
        //姓名医院登录记录
        this.getNameHos()
        this.restBtn()
      }
      if(e.key === '4'){
        this.liveWatch = false
        //聊天室记录
        this.getChattingRecords()
        this.restBtn()
      }
    },
    //重置
    restBtn(){
      this.watch = undefined
      this.liveData = undefined
      let tab = this.tab
      if(tab === '1'){
        //原始观看记录
        this.getOriginalViewing(this.page_no,this.page_size)
      }
      if(tab === '2'){
        //人员观看总时长
        this.getPeopleWatch()
      }
      if(tab === '3'){
        //姓名医院登录记录
        this.getNameHos()
      }
      if(tab === '4'){
        //聊天室记录
        this.getChattingRecords()
      }
    },
    //查询
    searchBtn(){
      //选择的直播间与观看类型
      const type = this.watch
      const streamId = this.liveData
      let tab = this.tab
      if(tab === '1'){
        //原始观看记录
        this.getOriginalViewing(this.page_no,this.page_size)
      }
      if(tab === '2'){
        //人员观看总时长
        this.getPeopleWatch()
      }
      if(tab === '3'){
        //姓名医院登录记录
        this.getNameHos()
      }
      if(tab === '4'){
        //聊天室记录
        this.getChattingRecords()
      }
    },
    //导出
    async exportBtn() {
      let tab = this.tab
      const liveMettingId = this.$route.query.live_metting_id
      let streamId = this.liveData
      if (streamId === undefined) {
        streamId = ''
      }
      let data = {
        type:this.watch,
        streamId:streamId,
        liveMettingId:liveMettingId,
        ExelName:'',
      }
      if (tab === '1') {
        //原始观看记录
        // getExel
        data.ExelName = '原始观看记录'
        const response = await Download_OriginalViewing_exal(data)
      }
      if (tab === '2') {
        //人员观看总时长
        data.ExelName = '人员观看总时长'
        const response2 = await Download_PeopleWatch_exal(data)
      }
      if (tab === '3') {
        //姓名医院登录记录
        data.ExelName = '姓名医院登录记录'
        const response3 = await Download_NameHos_exal(data)
      }
      if (tab === '4') {
        let data2 = {
          type:this.watch,
          roomId:streamId,
          liveMettingId:liveMettingId,
          ExelName:'',
        }
        //聊天室记录
        data2.ExelName = '聊天室记录'
        const response4 = await Download_ChattingRecords_exal(data2)
      }
    },

    //分页
    listChange_Original_viewing(page_no,page_size){
      this.page_no = page_no
      this.page_size = page_size
      //   //原始观看记录
      this.getOriginalViewing(page_no,page_size)
    },
    listChange_People_watch(page_no,page_size){
      this.page_no = page_no
      this.page_size = page_size
      this.getPeopleWatch(page_no,page_size)
    },
    listChange_name_hos(page_no,page_size){
      this.page_no = page_no
      this.page_size = page_size
      this.getNameHos(page_no,page_size)
    },
    listChange_chatting_records(page_no,page_size){
      this.page_no = page_no
      this.page_size = page_size
      this.getChattingRecords(page_no,page_size)
    },

    //删除确定
    delConfirm(id){
      this.delModelList(id)
      this.getChattingRecords(this.page_no,this.page_size)
    },
    //删除取消
    cancelDel(){

    },
    //开关
    async selectSwitch(id, isShow) {
      console.log(id, isShow)
      const data = {
        "vodHide": isShow
      }
      const response = await putVodHide(id, data)
      if (response.code === 0) {
        this.$message.success('修改成功')
        await this.getChattingRecords(this.page_no,this.page_size)
      } else {
        this.$message.warning("失败，请联系管理员", response.message)
      }
    },
    lookBtn(row) {
      this.visible = true
      this.lookName = row.name
      this.lookTel = row.tel
      this.liveModelId = row.memberId
      //获取列表
      this.getModelLiveList(row.memberId)
    },
    async getModelLiveList(memberId) {
      //获取列表
      const liveMettingId = this.$route.query.live_metting_id
      let data = {
        liveMettingId:liveMettingId,
        memberId:memberId
      }
      const response = await get_chatting_records(data)
      if (response.code === 0) {
        this.dataLook = response.data.rows
      } else {
        this.$message.warning("失败，请联系管理员", response.message)
      }
    },
    //删除
    async delModelList(liveChatIds) {
      const response = await delList(liveChatIds)
      if(response.code === 0){
        this.$message.success("删除成功！")
      }else {
        this.$message.warning("失败，请联系管理员", response.message)
      }
    },
    //查看对话框中的批量删除
    async delModelBtn(){
      console.log(this.selectedRowKeys)
      const liveChatIds = this.selectedRowKeys
      await this.delModelList(liveChatIds)
      //刷新列表
      await this.getModelLiveList(this.liveModelId)
      this.selectedRowKeys = []
    },
    onClose(){
      this.visible = false
    },
    //获取id
    onSelectChange(selectedRowKeys){
      // //选择的列表的id
      this.user_id = selectedRowKeys
      // //显示是否被选中
      this.selectedRowKeys = selectedRowKeys;
    },
    returnBtn(){
      window.history.back()
    }
  },
};
</script>

<style lang="scss" scoped>
.information{
  .information-top{
    height: auto;
    .information-top-title{
      padding-top: 20px;
      padding-left: 15px;
      font-size: 16px;
      font-weight: bold;
      color: black;
    }
  }
  .information-table{
    margin-left: 15px;
    margin-top: 15px;
    .information-table-btn{
      display: flex;
    }
  }
}
</style>